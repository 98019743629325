<template>
  <div id="smartai_teachermodule_page" style="margin: 20px" v-cloak>
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="模块名称" :label-width="formLabelWidth">
          <el-input v-model="form.module_name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitTeacherModule">确 定</el-button>
      </div>
    </el-dialog>

    <div style="margin-top: 20px">
      <el-button @click="openAddModule">新增模块</el-button>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%; margin-top: 20px"
      row-key="id"
      border
    >
      <el-table-column prop="module_name" label="模块名称"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { getTeacherModuleList, saveTeacherModule } from "@/api/admin.js";
export default {
  data() {
    return {
      tableData: [],
      dialogTitle: "新增模块",
      dialogFormVisible: false,
      form: {
        id: 0,
        module_name: "",
      },
      formLabelWidth: "120px",
    };
  },
  methods: {
    handleDelete: function (arr) {

      let vm = this;
      vm.$confirm("确认要删除该分类吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //   $.ajax({
          //     url: "index.php?act=score_smartai&op=delcate",
          //     type: "post",
          //     data: {
          //       cate_id: arr["id"],
          //     },
          //     dataType: "json",
          //     success: function (res) {
          //       if (res.code == 200) {
          //         vm.getTeacherModuleList();
          //       } else {
          //         vm.$message({
          //           type: "info",
          //           message: res.msg,
          //         });
          //       }
          //     },
          //     error: function () {},
          //   });
        })
        .catch(() => {
          vm.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleEdit: function (arr) {
      this.form.module_name = arr.module_name;
      this.form.id = arr.id;
      this.dialogTitle = "编辑模块";
      this.dialogFormVisible = true;
    },
    submitTeacherModule: function () {
      let vm = this;
      saveTeacherModule({
        ...vm.form,
      }).then((res) => {
        if (res.code == 1) {
          vm.dialogFormVisible = false;
          vm.resetForm();
          vm.getTeacherModuleList();
        }
      });
    },
    resetForm: function () {
      this.form = {
        id: 0,
        module_name: "",
      };
    },
    openAddModule: function () {
      this.resetForm();
      this.dialogTitle = "新增模块";
      this.dialogFormVisible = true;
    },
    getTeacherModuleList: function () {
      let vm = this;
      getTeacherModuleList().then((res) => {
        if (res.code == 1) {
          vm.tableData = res.data;
        }
      });
    },
  },
  created: function () {
    this.getTeacherModuleList();
  },
};
</script>
<style lang="less" scoped>
</style>